import {
  FETCH_FAIL,
  METER_LIST_SUCCESS,
  METERS_COST_SUCCESS,
  ENERGY_USAGE_HOURLY_SUCCESS,
  ENERGY_USAGE_DAILY_SUCCESS,
  ENERGY_USAGE_MONTHLY_SUCCESS,
  PRODUCT_SUCCESS,
  BILLS_SUCCESS,
  SET_LOADING,
  UNSET_LOADING,
  CLEAR_LOADING,
  SET_ENERGY_PERIOD,
  SET_CHART_START,
  SET_CHART_END,
  SET_CHART_TYPE
} from '../types';

const meterReducer = (state, action) => {
  switch (action.type) {
    case FETCH_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case METER_LIST_SUCCESS:
      const v = action.payload.map(v => v);
      return {
        ...state,
        meterList: v
        // loading: false,
      };

    case METERS_COST_SUCCESS:
      return {
        ...state,
        meterCosts: action.payload
        // loading: false,
      };

    case ENERGY_USAGE_HOURLY_SUCCESS:
      return {
        ...state,
        energyUsage: action.payload
        // energyUsagePeriod: 'day'
        // loading: false,
      };

    case ENERGY_USAGE_DAILY_SUCCESS:
      return {
        ...state,
        energyUsage: action.payload
        // energyUsagePeriod: 'month'
        // loading: false,
      };

    case ENERGY_USAGE_MONTHLY_SUCCESS:
      return {
        ...state,
        energyUsage: action.payload
        // energyUsagePeriod: 'year'
        // loading: false,
      };

    case BILLS_SUCCESS:
      return {
        ...state,
        bills: action.payload
        // loading: false,
      };

    case PRODUCT_SUCCESS:
      return {
        ...state,
        vendorProducts: action.payload
        // loading: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
        n_loading: state.n_loading + 1
      };

    case UNSET_LOADING:
      return {
        ...state,
        n_loading: state.n_loading - 1
      };

    case CLEAR_LOADING:
      return {
        ...state,
        n_loading: 0,
        loading: false
      };

    case SET_ENERGY_PERIOD:
      return {
        ...state,
        chartPeriod: action.payload
      };

    case SET_CHART_START:
      return {
        ...state,
        chartStart: action.payload
      };

    case SET_CHART_END:
      return {
        ...state,
        chartEnd: action.payload
      };

    case SET_CHART_TYPE:
      return {
        ...state,
        chartType: action.payload
      };

    default:
      return state;
  }
};

export default meterReducer;
