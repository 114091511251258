// Auth
export const SET_AUTH = 'SET_AUTH';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SEND_LOGIN_DETAILS_SUCCESS = 'SEND_LOGIN_DETAILS_SUCCESS';
export const SEND_LOGIN_DETAILS_FAIL = 'SEND_LOGIN_DETAILS_FAIL';

// Meters
export const FETCH_FAIL = 'METER_FETCH_FAIL';
export const METER_LIST_SUCCESS = 'METER_LIST_SUCCESS';
export const METERS_COST_SUCCESS = 'METERS_COST_SUCCESS';
export const ENERGY_USAGE_SUCCESS = 'ENERGY_USAGE_SUCCESS';
export const ENERGY_USAGE_HOURLY_SUCCESS = 'ENERGY_USAGE_HOURLY_SUCCESS';
export const ENERGY_USAGE_DAILY_SUCCESS = 'ENERGY_USAGE_DAILY_SUCCESS';
export const ENERGY_USAGE_MONTHLY_SUCCESS = 'ENERGY_USAGE_MONTHLY_SUCCESS';
export const SET_CHART_START = 'SET_CHART_START';
export const SET_CHART_END = 'SET_CHART_END';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const BILLS_SUCCESS = 'BILLS_SUCCESS';
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_ENERGY_PERIOD = 'SET_ENERGY_PERIOD';
export const SET_CHART_TYPE = 'SET_CHART_TYPE';
export const SET_CHART_SERIES = 'SET_CHART_SERIES';
export const SET_DURATION_CURVE_SERIES = 'SET_DURATION_CURVE_SERIES';
export const SET_DURATION_CURVE_YEAR = 'SET_DURATION_CURVE_YEAR';
export const SET_DURATION_CURVE_MONTH = 'SET_DURATION_CURVE_MONTH';
export const SET_PERIOD_BTN = 'SET_PERIOD_BTN';