import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from '../components/common/AuthenticatedRoute';
import UnauthenticatedRoute from "../components/common/UnauthenticatedRoute";


import loadable from '@loadable/component';

const Home = loadable(() => import('../components/home/Home'));
const Login = loadable(() => import('../components/auth/Login'));
const Signup = loadable(() => import('../components/auth/Signup'));
const Meters = loadable(() => import('../components/meters/Meters'));
const NewMeters = loadable(() => import('../components/meters/NewMeters'));
const Periods = loadable(() => import('../components/meters/Periods'));
const NotFound = loadable(() => import('../components/common/NotFound'));
const ForgotLoginDetails = loadable(() => import('../components/auth/ForgotLoginDetails'));
const ChangePassword = loadable(() => import('../components/auth/ChangePassword'));
const Settings = loadable(() => import("../components/settings/Settings"));
const About = loadable(() => import('../components/home/About'));
const MetersSimulation = loadable(() => import("../components/meters/Simulation/MetersSimulation"));
const SwitchProducts = loadable(() => import("../components/meters/products/SwitchProducts"));
const ViewTag = loadable(() => import("../components/tags/ViewTag"));
const GridRentalProducts = loadable(() => import('../components/products/GridRentalProducts'));
const GridRentalProduct = loadable(() => import('../components/products/GridRentalProduct'));
const GridRentalVendor = loadable(() => import('../components/products/GridRentalVendor'));
const TagAccess = loadable(() => import('../components/tags/TagAccess'));

const DashboardRoutes = () => {
  useEffect(() => {
    Login.preload();
  }, []);

  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} />
      <AuthenticatedRoute path="/meters/list" exact component={Home} />
      <UnauthenticatedRoute path="/login" exact component={Login} />
      <UnauthenticatedRoute path="/signup" exact component={Signup} />
      <Route path="/forgot-login-details" exact component={ForgotLoginDetails} />
      <AuthenticatedRoute path="/auth/update-password" exact component={ChangePassword} />
      <Route path="/about" exact component={About} />
      <Route path="/switchproducts/" exact component={SwitchProducts} />
      <Route path="/switchproducts/:initial_post_code" exact component={SwitchProducts} />
      <AuthenticatedRoute path="/settings" exact component={Settings} />
      <AuthenticatedRoute path="/gridrentalproducts" exact component={GridRentalProducts} />
      <AuthenticatedRoute path="/gridrentalproduct/:id" exact component={GridRentalProduct} />
      <AuthenticatedRoute path="/gridrentalproduct/:id/:initial_vendor_id" exact component={GridRentalProduct} />
      <AuthenticatedRoute path="/gridrentalvendor/:id" exact component={GridRentalVendor} />
      <AuthenticatedRoute path="/meters/view/:id" exact component={Meters} />
      <AuthenticatedRoute path="/meters/switchproducts/:id" exact component={SwitchProducts} />
      <AuthenticatedRoute path="/meters/simulation/:id" exact component={MetersSimulation} />
      <AuthenticatedRoute path="/meters/new" exact component={NewMeters} />
      <AuthenticatedRoute path="/meters/view/:id/:period" exact component={Periods} />
      <AuthenticatedRoute path="/tags/view/:tag_id" exact component={ViewTag} />
      <AuthenticatedRoute path="/view_access/:access_code" exact><TagAccess mode="View" /></AuthenticatedRoute>
      <AuthenticatedRoute path="/edit_access/:access_code" exact><TagAccess mode="Edit" /></AuthenticatedRoute>

      {/*Redirect*/}
      <Route path={`/errors/404`} component={NotFound} />
      <Redirect to="/errors/404" />
    </Switch>
  );
};

export default DashboardRoutes;
