import {
  // FETCH_FAIL,
  METER_LIST_SUCCESS,
  METERS_COST_SUCCESS,
  ENERGY_USAGE_SUCCESS,
  SET_LOADING,
  UNSET_LOADING,
  CLEAR_LOADING,
  SET_ENERGY_PERIOD,
  SET_CHART_START,
  SET_CHART_END,
  SET_CHART_TYPE,
  SET_CHART_SERIES,
  SET_PERIOD_BTN,
  SET_DURATION_CURVE_SERIES,
  SET_DURATION_CURVE_MONTH,
  SET_DURATION_CURVE_YEAR
} from '../types';

const chartReducer = (state, action) => {
  switch (action.type) {
    case METER_LIST_SUCCESS:
      const v = action.payload.map(v => v);
      return {
        ...state,
        meterList: v
        // loading: false,
      };

    case METERS_COST_SUCCESS:
      return {
        ...state,
        meterCostsChart: action.payload
        // loading: false,
      };

    case ENERGY_USAGE_SUCCESS:
      return {
        ...state,
        energyUsageChart: action.payload,
        // energyUsagePeriod: 'day'
        loading: false,
      };

    case SET_CHART_SERIES:
      return {
        ...state,
        chartSeries: action.payload
        // loading: false,
      };

    case SET_DURATION_CURVE_SERIES:
      return {
        ...state,
        durationCurveSeries: action.payload
        // loading: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
        // n_loading: state.n_loading + 1
      };

    case UNSET_LOADING:
      return {
        ...state,
        loading: false,
        // n_loading: state.n_loading - 1
      };

    case CLEAR_LOADING:
      return {
        ...state,
        n_loading: 0,
        loading: false
      };

    case SET_ENERGY_PERIOD:
      const start = new Date(state.chartStart.getFullYear(), state.chartStart.getMonth(), 1);
      const end = new Date(state.chartEnd.getFullYear(), state.chartEnd.getMonth() + 1, 1);
      if (state.chartPeriod === "day") {
        return {
          ...state,
          chartStart: start,
          chartEnd: end,
          chartPeriod: action.payload,
        };
      }

      return {
        ...state,
        chartPeriod: action.payload
      };

    case SET_PERIOD_BTN:
      return {
        ...state,
        chartStart: action.payload.start,
        chartEnd: action.payload.end
      };

    case SET_CHART_START:
      return {
        ...state,
        chartStart: action.payload
      };

    case SET_CHART_END:
      return {
        ...state,
        chartEnd: action.payload
      };

    case SET_DURATION_CURVE_YEAR:
      return {
        ...state,
        durationCurveYear: action.payload
      };

    case SET_DURATION_CURVE_MONTH:
      return {
        ...state,
        durationCurveMonth: action.payload
      };

    case SET_CHART_TYPE:
      return {
        ...state,
        chartType: action.payload
      };

    default:
      return state;
  }
};

export default chartReducer;
