import config, { getApiClient } from '../../config';
import axios from 'axios';
import Papa from 'papaparse'
import parseDate from 'postgres-date';

export const csvToJson = (csv) => {
  if (!csv.trim()) {
    return [];
  }
  const rv = Papa.parse(csv, {
    header: true, delimiter: ',', dynamicTyping: true,
    transform: (value, field) => {
      switch (field) {
        case 'is_favourite':
        case 'editable':
          return value === "t";
        case 'tags':
        case 'attributes':
        case 'product':
        case 'users':
          try {
            return value ? JSON.parse(value) : value;
          }
          catch (e) { console.log(e); }
          break;
        case "t":
        case "creation_time":
          return parseDate(value);
        default:
          return value;
      }
    }
  });
  return rv.data ?? [];
};

export const tagList = async (whereClause) => {
  // The supabase API doesn't work too well with nested or statements so I've left this handcrafted
  const options = {
    url: config.EPM_URL + `tags?order=name${whereClause ?? ""}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Prefer': 'count=exact',
      'Range': '0-99',
      Authorization: 'Bearer ' + localStorage.token //getCookie(AuthenticationCode)
    }
  };

  const res = await axios(options);
  if (res.status < 200 || res.status >= 300) {
    console.log("tagList error: ", res.statusText);
  }
  var contentRange = res.headers['content-range'];
  contentRange = contentRange && contentRange.split('/')[1];
  const totalCount = Number(contentRange) ?? 0;
  return { totalCount: totalCount, data: res.data };
};


export const meterList = async (orderBy, whereClause) => {
  // The supabase API doesn't work too well with nested or statements so I've left this handcrafted

  const options = {
    url: config.EPM_URL + `smartmeters?order=${orderBy}${whereClause ?? ""}`,
    method: 'get',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
      'Prefer': 'count=exact',
      'Range': '0-99',
      Authorization: 'Bearer ' + localStorage.token //getCookie(AuthenticationCode)
    }
  };

  const res = await axios(options);
  if (res.status < 200 || res.status >= 300) {
    console.log("meterList error: ", res.statusText);
  }
  var contentRange = res.headers['content-range'];
  contentRange = contentRange && contentRange.split('/')[1];
  const totalCount = Number(contentRange) ?? 0;
  return { totalCount: totalCount, data: csvToJson(res.data) };
};

export const executeAndLog = async (fname, f) => {
  const rv = await f;
  if (rv.error) {
    console.log(`${fname} error: ${rv.error}`);
  }
  return rv;
}

export const meterCount = async () => {
  const { count } = await executeAndLog('meterCount', getApiClient().from('smartmeters').select('id', { count: 'exact', head: true }));
  return { totalCount: count };
};


export const fetchFavourites = async () => {
  const { data } = await executeAndLog('fetchFavourites', getApiClient().from('favourites').select().maybeSingle());
  return data.favourites;
};

export const updateMeterFavourite = async (id, is_favourite) => {

  const json_data = {
    id: id,
    is_favourite: is_favourite,
  }

  await executeAndLog('updateMeterFavourite', getApiClient().rpc('update_smartmeter_favourite', json_data));
}

export const metersPerTag = async (tag_id) => {
  const { data } = await executeAndLog('metersPerTag', getApiClient().from('smartmeters_per_tag').select().eq('tag_id', tag_id));
  return data;
};

export const getMeter = async (id) => {

  const { data } = await executeAndLog('getMeter', getApiClient().from('smartmeters').select().eq('id', id));
  return data;
};

export const tagsPerTag = async (tag_id) => {
  const { data } = await executeAndLog('tagsPerTag', getApiClient().from('tags_per_tag').select('tag_id,name,creator,editable,edit_key,view_key,users,attributes,is_favourite').eq('owner_tag_id', tag_id));
  return data;
};

export const tagCount = async () => {
  const { count } = await executeAndLog('tagCount', getApiClient().from('tags').select('*', { count: 'exact', head: true }));
  return { totalCount: count };
};


export const tagParameterSuggestions = async () => {
  const { data } = await executeAndLog('tagParameterSuggestions', getApiClient().from('tags').select('attributes').neq('attributes', null));
  return data;
};

export const tagSuggestions = async () => {
  const { data } = await executeAndLog('tagSuggestions', getApiClient().from('tags').select('tag_id,name,creator').csv());
  return csvToJson(data);
};


export const resetTagLinks = async (tag_id) => {
  await executeAndLog('resetTagLinks', getApiClient().rpc('reset_tag_links', { tag_id: tag_id }));
};

export const deleteAccess = async (tag_id, user) => {
  await executeAndLog('deleteAccess', getApiClient().rpc('delete_access', { tag_id: tag_id, contactid: user }));
};

// Get energy usage
export const fetchEnergyUsage = async (id, startDate, endDate, period = 'daily') => {
  let method = '';

  // console.log("FETCH: ", startDate, endDate);
  switch (period) {
    case "day": //'hourly':
      method = 'get_meter_data_hourly';
      break;

    case "month": //'daily':
      method = 'get_meter_data_daily';
      break;

    case "year": //'monthly':
      method = 'get_meter_data_monthly';
      break;

    default:
      console.log('AN UNKNOWN PERIOD IS REQURESTED....');
      break;
  }

  const inputData =
  {
    id: id,
    start_time: startDate
  }

  if (endDate !== '') {
    inputData.end_time = endDate;
  }

  const { data } = await executeAndLog('fetchEnergyUsage', getApiClient().rpc(method, inputData).csv());
  return csvToJson(data).map(v => ({ ...v, t: parseDate(v.time) }));
};

// Get energy usage with current_year and last_year
export async function fetchEnergyUsageYearComparison(id, startDate, endDate, period = 'daily') {
  let previousYearStart = new Date(startDate.valueOf());
  let previousYearEnd = new Date(endDate.valueOf());
  previousYearStart.setFullYear(startDate.getFullYear() - 1);
  previousYearEnd.setFullYear(endDate.getFullYear() - 1);
  const thisYear = await fetchEnergyUsage(id, startDate, endDate, period);
  const previousYear = await fetchEnergyUsage(id, previousYearStart, previousYearEnd, period);
  return previousYear.concat(thisYear);
};

// Get energy costs
export const fetchVirtualCost = async (id, productId,
  taxId,
  streamPricing,
  maxPower,
  maxPowerPercent,
  energyReduction,
  reactiveEnergyReduction,
  maxReactiveEnergyReduction,
  reducedEnergyPrice,
  minimumDate) => {

  const inputData = {
    smartmeter_ids: [id],
    product_id: productId,
    tax_id: taxId,
    minimumdate: minimumDate,
    energy_reduction: energyReduction,
    power_guard: maxPower,
    maximum_power_reduction: maxPowerPercent,
    reactive_energy_reduction: reactiveEnergyReduction,
    maximum_reactive_reduction: maxReactiveEnergyReduction,
    energy_price_reduction: reducedEnergyPrice,
    stream_pricing: streamPricing,
  };
  const { data } = await executeAndLog('fetchEnergyUsage', getApiClient().rpc('get_virtual_cost', inputData).csv());
  return csvToJson(data);
};

export const fetchEnergyCost = async (id) => {
  const { data } = await executeAndLog('fetchEnergyCost', getApiClient().rpc('get_meter_data_monthly', { id: id }).csv());
  return csvToJson(data).map(v => ({ ...v, period: v.time.substring(0, 7) }));
};


export const fetchEnergyCostPerTag = async (tag_id) => {
  const { data } = await executeAndLog('fetchEnergyCostPerTag', getApiClient().rpc('get_meter_data_per_tag', { tag_id: tag_id }).csv());
  return csvToJson(data);
};

export const fetchEnergyCostWeeklyPerTag = async (tag_id, minyear, maxyear) => {
  const { data } = await executeAndLog('fetchEnergyCostWeeklyPerTag', getApiClient().rpc('get_meter_data_weekly_per_tag', { tag_id: tag_id, start_time: String(minyear - 1) + "-01-01", end_time: String(maxyear + 1) + "-01-01" }).csv());
  return csvToJson(data).map(v => ({ ...v, t: parseDate(v.time) }));
};

export const mergeEnergyCostAndUsage = async (meterID, startDate, endDate) => {
  let energyCost = await fetchEnergyCost(meterID);

  energyCost = energyCost
    .map(v => ({ ...v, t: parseDate(v.time), period: v.time.substring(0, 7) }))
    .filter(v => v.t >= startDate && v.t < endDate);

  return await energyCost;
};


// Get Products (what product the customers has)
export const getMeterProducts = async id => {
  if (id) {
    const { data } = await executeAndLog('getMeterProducts', getApiClient().from('smartmeter_available_products').select().eq('id', id));
    return data;
  }
  else {
    const { data } = await executeAndLog('getMeterProducts', getApiClient().from('products').select());
    return data;
  }
};

// Get roles for current user
export const getRoles = async () => {
  const { data } = await executeAndLog('getRoles', getApiClient().from('user_roles').select());
  return data;
};