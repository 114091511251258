import React, { useReducer } from 'react';
import AuthContext from './authContext';
import authReducer from './authReducer';
import config from '../../config';
import { GoTrueClient } from '@supabase/gotrue-js';
import axios from 'axios';

import { extractNameFromGoolge } from './helperFunctions';

/*
  ---- USE THIS WITH GO-TRUE ----
*/

// const GOTRUE_URL_AUTOCONFIRM = 'http://localhost:9999/auth/v1';
const GOTRUE_URL_AUTOCONFIRM = `${config.AUTH_URL.slice(0,-1)}`; //'http://localhost:9999';
const AME_UI_URL = `${config.AME_UI_URL}`;

// const supabaseAnon =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE';
const auth = new GoTrueClient({
  url: GOTRUE_URL_AUTOCONFIRM,
  // fetch: axios,
  headers: {
    accept: 'json'
    // apikey: supabaseAnon,
  }
});

const AuthState = props => {
  // const cookies = new Cookies();

  const initialState = {
    session: auth.session(), // Use setState is it already memoized (to avoid infinity loop)
    email: localStorage.getItem('email') ?? '',
    phone: localStorage.getItem('phone') ?? '',
    token: null,
    rememberMe: false,
    // refreshToken: cookies.get('rtoken'),
    isAuthenticated: null,
    loading: false,
    loginDetailsSent: false,
    loginDetailsCalled: false,
    newPasswordCreated: false,
    error: null
  };

  // let [session, setSession] = useState(auth.session());

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Keep the session up to date
  auth.onAuthStateChange((_event, session) => {
    // console.log('AUTHSTATE: ', _event, session);
    // setSession(session);
    if (session?.access_token) {
      // console.log('--- SET TOKEN --- :', session.access_token);
      localStorage.setItem('token', session.access_token);
      dispatch({ type: 'LOGIN_SUCCESS', payload: session });
      return;
    }
    dispatch({ type: 'SET_SESSION', payload: session });
  });

  async function handleOAuthLogin(provider, redirectURI) {
    if (redirectURI === '') {
      redirectURI = 'home';
    }
    // let { error } = await auth.signIn({ provider }, { redirectTo: 'http://localhost:3001/welcome' });
    let { error, user, session } = await auth.signIn({ provider }, { redirectTo: `${AME_UI_URL.slice(0,-1)}${redirectURI}` });
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
    console.log('Logged in with provider', provider);

    // Login to the new user and register contact

    if (!session?.access_token) {
      console.log('Login error...');
      return;
    }

    let n = { family_name: '', given_name: '' };
    if (provider === 'google') {
      n = extractNameFromGoolge(user);
    }

    handleRegisterContact(user.id, n.given_name, n.family_name, user.email, user.phone);
  }

  async function handleRegisterContact(user_id, first_name, last_name, email, mobile) {
    const options = {
      url: config.EPM_URL + 'rpc/signup_contact',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: {
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile
      }
    };

    try {
      const res = await axios(options);
      if (res.status < 200 || res.status >= 300) {
        console.log(res.statusText);
      }
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: err.response.data.message
      });
    }
  }

  async function handleVerifyOtp(phone, token) {
    let data = await auth.verifyOTP({ phone: phone, token: token });
    console.log(data);
  }

  async function handleSendOtp(phone) {
    let data = await auth.signIn({ phone: phone });
    console.log(data);
  }

  async function handleEmailSignIn(email, password, rememberMe) {
    // if (redirectURI === "") {
    //   redirectURI = "/home";
    // }

    if (rememberMe) {
      localStorage.setItem('email', email);
    } else {
      localStorage.removeItem('email');
    }

    // let { error, user } = await auth.signIn({ email, password }, { redirectTo: `${AME_UI_URL}${redirectURI}` });
    let { error, user } = await auth.signIn({ email, password });
    if (!error && !user) alert('Sjekk din epost for innloggingslink!');
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }

  async function handleEmailSignUp(email, password, first_name, last_name, mobile) {
    let { error, user } = await auth.signUp(
      { email, password },
      {
        data: {
          given_name: first_name, // first_name (given_name is used by oauth providers)
          family_name: last_name, // last_name (family_name is used by oauth providers)
          email: email,
          mobile: mobile
        }
      }
    );
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      return;
    }

    if (!user) {
      console.log('Error fetching user-info');
    }

    // Login to the new user and register contact
    handleRegisterContact(user.id, first_name, last_name, email, mobile);
  }

  // TEMP
  async function handlePhoneSignIn(phone, password, rememberMe) {
    if (rememberMe) {
      localStorage.setItem('phone', phone);
    } else {
      localStorage.removeItem('phone');
    }
    let { error, user } = await auth.signIn({ phone: phone, password: password });
    if (!error && !user) alert('Check your phone for the login link!');
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }

  // TEMP
  async function handlePhoneSignUp(phone, password) {
    let { error } = await auth.signUp({ phone: phone, password: password });
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }

  async function handleSignOut() {
    let { error } = await auth.signOut();
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
    dispatch({ type: 'LOGOUT' });
  }

  async function forgotPassword(email) {
    // var email = prompt('Please enter your email:');
    if (email === null || email === '') {
      window.alert('Du må oppgi din epost.');
    } else {
      let { error } = await auth.api.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/auth/update-password`
      });
      if (error) {
        console.log('Error: ', error.message);
        dispatch({ type: 'SET_ERROR', payload: error.message });
      } else {
        // alert('Password recovery email has been sent.');
        // alert('Vi har sendt en epost med en kode du kan benytte for å tilbakestille passordet.');
        dispatch({ type: 'SEND_LOGIN_DETAILS_SUCCESS', payload: true });
      }
    }
  }

  async function changePassword(oldPassword, newPassword) {
    // -- Check existing password

    const options = {
      url: config.AUTH_URL + 'token?grant_type=password',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.session?.access_token}`,
      },
      data: {
        email: state.session?.user?.email,
        password: oldPassword
      }
    };
    try {
      const res = await axios(options);
      if (res.status < 200 || res.status >= 300) {
        dispatch({
          type: "SET_ERROR",
          payload: res.data.error_description
        });
        return
      }
      
    } catch (err) {
      console.log(err)
      dispatch({
        type: "SET_ERROR",
        payload: {message: "Skriv inn ditt eksisterende passord korrekt"}
      });
      return
    }


    // -- Update password

    let data = await auth.update({ password: newPassword });
    // console.log("TEST", data)
    if (!data.error) {
      dispatch({ type: 'CLEAR_ERRORS' });
      dispatch({ type: 'PASSWORD_UPDATED' });      
    } else {
      dispatch({ type: 'SET_ERROR', payload: data.error });
    }
  }

  async function changePasswordAfterReset(newPassword) {
    // -- Update password
    let data = await auth.update({ password: newPassword });
    // console.log("TEST", data)
    if (!data.error) {
      dispatch({ type: 'CLEAR_ERRORS' });
      dispatch({ type: 'PASSWORD_UPDATED' });      
    } else {
      dispatch({ type: 'SET_ERROR', payload: data.error });
    }
  }

  async function updateUserData(json_metadata) {
    let { user, error } = await auth.update({
      data: { ...json_metadata } // Will be added to metadata in auth.users table
    });
    if (!error && !user) alert('Sjekk din epost for innloggingslink!');
    if (error) {
      console.log('Error: ', error.message);
      dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  }

  // Logout
  const logout = () => dispatch({ type: 'LOGOUT' });

  const clearErrors = () => {
    dispatch({
      type: 'CLEAR_ERRORS'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        session: state.session, //state.session,
        token: state.token,
        loading: state.loading,
        error: state.error,
        loginDetailsSent: state.loginDetailsSent,
        loginDetailsCalled: state.loginDetailsCalled,
        newPasswordCreated: state.newPasswordCreated,
        handleOAuthLogin,
        handleVerifyOtp,
        handleSendOtp,
        handleEmailSignIn,
        handleEmailSignUp,
        handleSignOut,
        forgotPassword,
        changePassword,
        changePasswordAfterReset,
        updateUserData,
        logout,
        handlePhoneSignIn,
        handlePhoneSignUp,
        clearErrors
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
