import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import AuthState from "./context/authContextGoTrue/AuthState";

import { AppProvider } from './context/appContext/appContext';
import MeterState from './context/meterContext/MeterState';
import ChartState from './context/chartContext/ChartState';


import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

import './App.css';


function App() {
  return (
    <AppProvider>
      <AuthState>
        <MeterState>
          <ChartState>
            <Router basename={process.env.PUBLIC_URL} fallback={<span />}>
              <Layout />
            </Router>
          </ChartState>
        </MeterState>
      </AuthState>
    </AppProvider>
  );
}


export default App;
