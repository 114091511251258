import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Alert, Button } from 'reactstrap';
import { useAuthContext } from '../context/authContextGoTrue/authContext';

import { LinkContainer } from 'react-router-bootstrap';

import { useHistory, Link } from 'react-router-dom';
import DashboardRoutes from './DashboardRoutes';
import Logo from '../components/logo';
import { Image } from 'react-bootstrap';
// import Cookies from 'universal-cookie';

function DashboardLayout({ location }) {
  const history = useHistory();
  // const cookies = new Cookies();

  const { session, handleSignOut } = useAuthContext();
  const [showNewVersionBanner, setShowNewVersionBanner] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleLogout = () => {
    handleSignOut();
    history.push("/")
    // cookies.set('AuthenticationCode', '');
  };

  return (
    <div className="App py-3 px-2 px-sm-3 px-lg-5">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <LinkContainer to="/meters/list/">
          <Navbar.Brand className="font-weight-normal text-muted"><Image className="mr-2" src={process.env.PUBLIC_URL + "/favicon-32x32.png"} /><Logo /></Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname}>
            {session ? (
              <>
                {
                  localStorage.access_types?.includes('Administrator') &&
                  <LinkContainer to="/gridrentalproducts">
                    <Nav.Link>Produkter</Nav.Link>
                  </LinkContainer>
                }

                <LinkContainer to="/settings">
                  <Nav.Link>Innstillinger</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Nav.Link>Om oss</Nav.Link>
                </LinkContainer>

                <Nav.Link onClick={handleLogout}>Logg ut</Nav.Link>
              </>
            ) : (
              <>
                <LinkContainer to="/switchproducts">
                  <Nav.Link>Bytt leverandør</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Nav.Link>Om oss</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer to="/signup">
                  <Nav.Link>Registrer</Nav.Link>
                </LinkContainer> */}
                <LinkContainer to="/login">
                  <Nav.Link>Logg Inn</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {localStorage.access_types && localStorage.access_types?.includes("Demo") ?
        <Row>
          <Col>

            <Alert color="success">Du er nå logget inn med en demonstrasjons-bruker. Hvis du liker der du ser; <Link onClick={() => {
              handleSignOut();
              // cookies.set('AuthenticationCode', '');
              history.push('/signup');
            }}>registrere deg her</Link>. Helt gratis.</Alert>
          </Col>
        </Row> : null
      }
      {showNewVersionBanner &&
        <Alert color="success">
          <Row>
            <Col>
              En ny versjon av allmy.energy kommer snart.  <a href="https://new-app.allmy.energy"
              >Prøv den her</a>.
            </Col>
            <Col xs="auto">
              <Button onClick={() => setShowNewVersionBanner(false)} size='sm'>Lukk</Button>
            </Col>
          </Row>
        </Alert>
      }
      {/* Routes */}
      <DashboardRoutes />
    </div >
  );
}

export default DashboardLayout;
