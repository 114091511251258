import React, { useReducer, useEffect } from 'react';
import MeterContext from './chartContext';
import chartReducer from './chartReducer';
import {
  FETCH_FAIL,
  SET_LOADING,
  UNSET_LOADING,
  CLEAR_LOADING,
  SET_ENERGY_PERIOD,
  SET_CHART_START,
  SET_CHART_END,
  SET_CHART_TYPE,
  SET_CHART_SERIES,
  SET_DURATION_CURVE_SERIES,
  SET_PERIOD_BTN,
  SET_DURATION_CURVE_YEAR,
  SET_DURATION_CURVE_MONTH
} from '../types';
import axios from 'axios';
import config from '../../config';
import {
  fetchEnergyUsage,
  fetchEnergyUsageYearComparison,
  mergeEnergyCostAndUsage,
  csvToJson
} from '../../data_fetch/smartmeters/smartmeterData';

const ChartState = props => {
  const initialState = {
    chartPeriod: 'year', // Chart-period is how long period the chart will show
    chartStart: new Date(2019, new Date().getMonth(), 1),
    chartEnd: new Date(new Date().getFullYear() + 1, 0, 1),
    chartType: 'COST_ENERGY',
    energyUsageChart: [],
    meterCostsChart: [],
    chartSeries: [],
    durationCurveSeries: [],
    durationCurveMonth: "",
    durationCurveYear: new Date().getFullYear(),
    loading: false,
    error: null,
    n_loading: 0
  };

  const [state, dispatch] = useReducer(chartReducer, initialState);


  // Get energy usage with current_year and last_year
  const getDurationCurveSeries = async (id) => {
    let url = config.EPM_URL + `rpc/get_duration_curves`;

    const options = {
      url: url,
      method: 'post',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token
      },
      data: { id }
    };

    try {
      const res = await axios(options);
      dispatch({
        type: SET_DURATION_CURVE_SERIES,
        payload: csvToJson(res.data)
      });
    } catch (err) {
      console.log('err: getEnergyUsage');
      dispatch({
        type: FETCH_FAIL,
        payload: err.response.data.message
      });
    }
  };

  const getChartSeries = async (meterID, showYearByYear) => {
    setLoading();
    let res = null;

    if (state.chartPeriod === 'year') {
      res = await mergeEnergyCostAndUsage(meterID, state.chartStart, state.chartEnd);
    } else {
      if (showYearByYear) {
        res = await fetchEnergyUsageYearComparison(meterID, state.chartStart, state.chartEnd, state.chartPeriod);
      } else {
        res = await fetchEnergyUsage(
          meterID,
          state.chartStart.toISOString(),
          state.chartEnd.toISOString(),
          state.chartPeriod
        );
      }
    }

    dispatch({
      type: SET_CHART_SERIES,
      payload: res
    });

    unsetLoading();
  };

  const setDayPeriodBtn = () => {
    if (typeof state.chartSeries[state.chartSeries.length - 1] === 'undefined') {
      return;
    }
    const lastDate = new Date(state.chartSeries[state.chartSeries.length - 1].t);
    const start = new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());
    const end = new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate() + 1);

    dispatch({
      type: SET_PERIOD_BTN,
      payload: { start: start, end: end }
    });
  };

  const setMonthPeriodBtn = () => {
    if (typeof state.chartSeries[state.chartSeries.length - 1] === 'undefined') {
      return;
    }
    const lastDate = new Date(state.chartSeries[state.chartSeries.length - 1].t);
    const start = new Date(lastDate.getFullYear(), lastDate.getMonth(), 1);
    const end = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 1);

    dispatch({
      type: SET_PERIOD_BTN,
      payload: { start: start, end: end }
    });
  };

  const setSeriesRange = (start, end) => {
    dispatch({
      type: SET_PERIOD_BTN,
      payload: { start: start, end: end }
    });
  };

  const setChartPeriod = period => dispatch({ type: SET_ENERGY_PERIOD, payload: period });

  const setChartStart = period => dispatch({ type: SET_CHART_START, payload: period });

  const setChartEnd = period => dispatch({ type: SET_CHART_END, payload: period });

  const setChartType = chartType => dispatch({ type: SET_CHART_TYPE, payload: chartType });

  const setDurationCurveYear = year => dispatch({ type: SET_DURATION_CURVE_YEAR, payload: year });

  const setDurationCurveMonth = month => dispatch({ type: SET_DURATION_CURVE_MONTH, payload: month });

  const setLoading = () => dispatch({ type: SET_LOADING });

  const unsetLoading = () => dispatch({ type: UNSET_LOADING });

  const clearLoading = () => dispatch({ type: CLEAR_LOADING });

  const setPreviousChartPeriod = () => {
    let nextPeriodStart = state.chartStart;
    let nextPeriodEnd = state.chartEnd;

    switch (state.chartPeriod) {
      case 'day':
        nextPeriodStart = new Date(nextPeriodStart.getFullYear(), nextPeriodStart.getMonth(), nextPeriodStart.getDate() - 1);
        nextPeriodEnd = new Date(nextPeriodEnd.getFullYear(), nextPeriodEnd.getMonth(), nextPeriodEnd.getDate() - 1);
        setSeriesRange(nextPeriodStart, nextPeriodEnd);
        break;

      case 'month':
        nextPeriodStart = new Date(nextPeriodStart.getFullYear(), nextPeriodStart.getMonth() - 1, 1);
        nextPeriodEnd = new Date(nextPeriodEnd.getFullYear(), nextPeriodEnd.getMonth() - 1, 1);
        setSeriesRange(nextPeriodStart, nextPeriodEnd);
        break;

      case 'year':
        nextPeriodStart = new Date(nextPeriodStart.getFullYear() - 1, nextPeriodStart.getMonth(), 0);
        nextPeriodEnd = new Date(nextPeriodEnd.getFullYear() - 1, 0, 1);
        setSeriesRange(nextPeriodStart, nextPeriodEnd);
        break;

      default:
        console.log('AN UNKNOWN PERIOD IS REQURESTED....');
        break;
    }
  };

  const setNextChartPeriod = () => {
    let nextPeriodStart = state.chartStart;
    let nextPeriodEnd = state.chartEnd;

    switch (state.chartPeriod) {
      case 'day':
        nextPeriodStart = new Date(nextPeriodStart.getFullYear(), nextPeriodStart.getMonth(), nextPeriodStart.getDate() + 1);
        nextPeriodEnd = new Date(nextPeriodEnd.getFullYear(), nextPeriodEnd.getMonth(), nextPeriodEnd.getDate() + 1);
        setSeriesRange(nextPeriodStart, nextPeriodEnd);
        break;

      case 'month':
        nextPeriodStart = new Date(nextPeriodStart.getFullYear(), nextPeriodStart.getMonth() + 1, 1);
        nextPeriodEnd = new Date(nextPeriodEnd.getFullYear(), nextPeriodEnd.getMonth() + 1, 1);
        setSeriesRange(nextPeriodStart, nextPeriodEnd);
        break;

      case 'year':
        nextPeriodStart = new Date(nextPeriodStart.getFullYear() + 1, nextPeriodStart.getMonth(), 0);
        nextPeriodEnd = new Date(nextPeriodEnd.getFullYear() + 1, 0, 1);
        setSeriesRange(nextPeriodStart, nextPeriodEnd);
        break;

      default:
        console.log('AN UNKNOWN PERIOD IS REQURESTED....');
        break;
    }
  };

  // Clear loading state if n_loading <= 0
  useEffect(() => {
    if (state.n_loading <= 0) {
      clearLoading();
    }
  }, [state.n_loading]);

  return (
    <MeterContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        chartStart: state.chartStart,
        chartEnd: state.chartEnd,
        chartPeriod: state.chartPeriod,
        durationCurveSeries: state.durationCurveSeries,
        durationCurveYear: state.durationCurveYear,
        durationCurveMonth: state.durationCurveMonth,
        chartType: state.chartType,
        energyUsageChart: state.energyUsageChart,
        meterCostsChart: state.meterCostsChart,
        chartSeries: state.chartSeries,
        setChartPeriod,
        setChartStart,
        setChartEnd,
        setChartType,
        getChartSeries,
        setDayPeriodBtn,
        setMonthPeriodBtn,
        setSeriesRange,
        setPreviousChartPeriod,
        setNextChartPeriod,
        getDurationCurveSeries,
        setDurationCurveYear,
        setDurationCurveMonth
      }}
    >
      {props.children}
    </MeterContext.Provider>
  );
};

export default ChartState;
