import { PostgrestClient } from "@supabase/postgrest-js";

const config =
{
  EPM_URL: window.app && window.app.env.EPM_URL,        // Allmyenergy API URL
  AUTH_URL: window.app && window.app.env.AUTH_URL,      // Auth back-end URL
  AME_UI_URL: window.app && window.app.env.AME_UI_URL,  // Allmyenergy UI URL
  development: window.app && window.app.env.development
};

export const getApiClient = () => config.EPM_URL && new PostgrestClient(config.EPM_URL.slice(0,-1), { headers: localStorage.token ? { "Authorization": "Bearer " + localStorage.token } : null });

export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent'
};

export default config;