import { getRoles } from '../../data_fetch/smartmeters/smartmeterData';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SESSION':
      return {
        ...state,
        session: action.payload,
      };

    case 'USER_LOADED':
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload
      };

    case 'LOGIN_SUCCESS':
      getRoles().then((res) => {
        if (res && res.length > 0 && res[0]['role']) {
          localStorage.setItem('access_types', res[0]['role']);
        }
      });

      return {
        ...state,
        session: action.payload,
        token: action.payload.access_token,
        email: action.payload.user?.email,
        isAuthenticated: true,
        error: null,
      };

    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        token: null,
        email: null,
        session: null
      };

    case 'SEND_LOGIN_DETAILS_SUCCESS':
      return {
        ...state,
        loginDetailsSent: action.payload,
        loginDetailsCalled: true
      };

    case 'PASSWORD_UPDATED':
      return {
        ...state,
        newPasswordCreated: true,
        loading: false
      };

    case 'CLEAR_ERRORS':
      return {
        ...state,
        error: null,
        loginDetailsCalled: false,
        newPasswordCreated: false,
      };

    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default authReducer;
