import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import MeterContext from './meterContext';
import meterReducer from './meterReducer';
import {
  FETCH_FAIL,
  METERS_COST_SUCCESS,
  ENERGY_USAGE_HOURLY_SUCCESS,
  ENERGY_USAGE_DAILY_SUCCESS,
  ENERGY_USAGE_MONTHLY_SUCCESS,
  BILLS_SUCCESS,
  SET_LOADING,
  UNSET_LOADING,
  CLEAR_LOADING,
  SET_ENERGY_PERIOD,
  SET_CHART_START,
  SET_CHART_END,
  SET_CHART_TYPE
} from '../types';
import config from '../../config';
import { csvToJson } from '../../data_fetch/smartmeters/smartmeterData';

const MeterState = props => {
  const initialState = {
    meterList: [],
    meterCosts: [],
    energyUsage: [],
    chartPeriod: 'month', // Chart-period is how long period the chart will show
    chartStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    chartEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString(),
    chartType: "ENERGY_TEMP",
    bills: [],
    loading: true,
    error: null,
    n_loading: 0
  };

  const [state, dispatch] = useReducer(meterReducer, initialState);

  // Get cost pr. meter
  const getMetersCosts = async id => {
    setLoading();
    const options = {
      url: config.EPM_URL + 'rpc/get_meter_data_monthly',
      method: 'post',
      headers: {
        Accept: "text/csv",
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token
      },
      data: { id: id }
    };

    try {
      const res = await axios(options);
      dispatch({
        type: METERS_COST_SUCCESS,
        payload: csvToJson(res.data).map(v => ({ ...v, period: v.time.substring(0, 7) }))
      });
    } catch (err) {
      console.log('err: getMetersCosts');
      console.log(err);
      dispatch({
        type: FETCH_FAIL,
        payload: err.response ? err.response.data.message : err
      });
    }
    unsetLoading();
  };

  // Get energy usage
  const getEnergyUsage = async (id, startDate, endDate = '', period = 'daily') => {
    setLoading();
    let url = '';
    let type = '';

    switch (period) {
      case 'hourly':
        url = config.EPM_URL + `rpc/get_meter_data_hourly`;
        type = ENERGY_USAGE_HOURLY_SUCCESS;
        break;

      case 'daily':
        url = config.EPM_URL + `rpc/get_meter_data_daily`;
        type = ENERGY_USAGE_DAILY_SUCCESS;
        break;

      case 'monthly':
        url = config.EPM_URL + `rpc/get_meter_data_monthly`;
        type = ENERGY_USAGE_MONTHLY_SUCCESS;
        break;

      default:
        console.log('AN UNKNOWN PERIOD IS REQURESTED....');
        break;
    }


    const data =
    {
      id: id,
      start_time: startDate
    }

    if (endDate !== '') {
      data.end_time = endDate;
    }


    const options = {
      url: url,
      method: 'post',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token
      },
      data
    };

    try {
      const res = await axios(options);
      dispatch({
        type: type,
        payload: csvToJson(res.data)
      });
    } catch (err) {
      console.log('err: getEnergyUsage');
      dispatch({
        type: FETCH_FAIL,
        payload: err.response ? err.response.data.message : err
      });
    }
    unsetLoading();
  };

  // Get Bill
  const getBillsForPeriod = async (id, period) => {
    setLoading();
    let url = config.EPM_URL + `rpc/get_meter_bill`;

    const options = {
      url: url,
      method: 'post',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token
      },
      data: { id: id, period: period }
    };


    try {
      const res = await axios(options);
      dispatch({
        type: BILLS_SUCCESS,
        payload: csvToJson(res.data)
      });
    } catch (err) {
      console.log('err: getEnergyUsage');
      dispatch({
        type: FETCH_FAIL,
        payload: err.response ? err.response.data.message : err
      });
    }
    unsetLoading();
  };

  // update smartmeter metadata
  const updateSmartmeters = async (id, smartmeter_name, product_id, tax_id, stream_prices, tags, allow_access) => {

    const json_data = {
      id: id,
      smartmeter_name: smartmeter_name,
      product_id: product_id,
      tax_id: tax_id,
      stream_prices: stream_prices,
      tags: tags,
      allow_access: allow_access
    };

    const options = {
      url: config.EPM_URL + `rpc/update_smartmeter`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token
      },
      data: json_data
    };

    try {
      await axios(options);

      getMetersCosts(id);
    } catch (err) {
      console.log(err);
      dispatch({
        type: FETCH_FAIL,
        payload: err.response ? err.response.data.message : err
      });
      return err.response ? err.response.data.message : err;
    }
  };

  const setChartPeriod = period => dispatch({ type: SET_ENERGY_PERIOD, payload: period });

  const setChartStart = period => dispatch({ type: SET_CHART_START, payload: period });

  const setChartEnd = period => dispatch({ type: SET_CHART_END, payload: period });

  const setChartType = chartType => dispatch({ type: SET_CHART_TYPE, payload: chartType });

  const setLoading = () => dispatch({ type: SET_LOADING });

  const unsetLoading = () => dispatch({ type: UNSET_LOADING });

  const clearLoading = () => dispatch({ type: CLEAR_LOADING });

  // Clear loading state if n_loading <= 0
  useEffect(() => {
    if (state.n_loading <= 0) {
      clearLoading();
    }
  }, [state.n_loading]);

  return (
    <MeterContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        meterList: state.meterList,
        meterCosts: state.meterCosts,
        energyUsage: state.energyUsage,
        chartStart: state.chartStart,
        chartEnd: state.chartEnd,
        chartPeriod: state.chartPeriod,
        chartType: state.chartType,
        bills: state.bills,
        getMetersCosts,
        getEnergyUsage,
        getBillsForPeriod,
        setChartPeriod,
        setChartStart,
        setChartEnd,
        setChartType,
        updateSmartmeters
      }}
    >
      {props.children}
    </MeterContext.Provider>
  );
};

export default MeterState;
