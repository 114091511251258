import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
// import ReactDOM from 'react-dom'; // React 17
import { createRoot } from 'react-dom/client'; // React 18
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/initFA';

// React 18
const root = createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode >
    <App />
    // </React.StrictMode>}
);

// React 17
// ReactDOM.render( 
//     <React.StrictMode >
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();