
export function extractNameFromGoolge(user) {
  let user_name={family_name: "", given_name: ""}
  if (!user?.user_metadata?.family_name || !user?.user_metadata?.given_name) {
    let names = user?.user_metadata?.full_name?.n.split(" ", -1)

    user_name.family_name = names.pop() // last word is last name
    user_name.given_name = names.join(" ")
    return user_name
  }

  user_name.family_name = user?.user_metadata?.family_name
  user_name.given_name = user?.user_metadata?.given_name

  return user_name

}
